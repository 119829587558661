.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.project-description {
  transition: color 0.3s ease-in-out;
}


.project-button:hover .project-description {
  color: black;
}


.learn-more {
  transition: color 0.3s ease-in-out;
}

.learn-more-button:hover .learn-more{
  color: rgb(153 27 27);
  text-decoration: underline;
}

.learn-more::after {
  content: '>';
  transition: content 0.3s ease-in-out;
}

.learn-more-button:hover .learn-more::after {
  content: '->';
  color: rgb(153, 27, 27);
}



  
.relative:hover #dropdownDelay {
  opacity: 1;
  visibility: visible;
  /* transform: scale(1); */
}

.disable-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #B00000;
  color: white; 
  padding: 10px 20px; 
  border-radius: 50px;
  /* font-weight: bold;
  font-size: 16px; */
  cursor: pointer;
  border: none; 
  outline: none; 
  transition: background-color 0.3s, color 0.3s;
  pointer-events: auto;
}




.session-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D00200; 
  color: white; 
  padding: 10px 20px; 
  border-radius: 50px;
  /* font-weight: bold;
  font-size: 16px; */
  cursor: pointer;
  border: none; 
  outline: none; 
  transition: background-color 0.3s, color 0.3s;
  pointer-events: auto;
}

.session-button:hover {
  background-color: #B00000;
  color: #F1F1F1; 
}


.session-button:active {
  background-color: white;
  color: #D00200;
  outline: 2px solid #D00200;
  pointer-events: auto;
}

.facebook-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:#181818;
 
  color: white; 
  padding: 10px 20px; 
  border-radius: 50px;
  cursor: pointer;
  border: none; 
  outline: none; 
  transition: background-color 0.3s, color 0.3s;
}

.facebook-button:hover {
  background-color: #868686;  
  color: #ffffff;        
}



.facebook-button:active {
  background-color: white;
  color: #181818;
  outline: 2px solid #181818;
  
}


.session-button-icon {
  margin-left: 8px; 
  height: 20px;
  width: 20px;
  stroke: currentColor;
}


.sponsor-package-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #868686;
  color: white; 
  padding: 10px 20px; 
  border-radius: 50px;
  /* font-weight: bold;
  font-size: 16px; */
  cursor: pointer;
  border: none; 
  outline: none; 
  transition: background-color 0.3s, color 0.3s;
}

.sponsor-package-button:hover {
  background-color: #6b6b6b; 
  color: #f1f1f1; 
}


.sponsor-package-button:active {
  background-color: white;
  color: #868686;
  outline: 2px solid #868686;
  
}


